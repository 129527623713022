import React from "react"
import styled from "styled-components"
import { media } from "styles/media"
import herocardBG from "images/ui/herocard-bg.svg"

const StyledCard = styled.div`
  position: relative;
  z-index: 10;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(37, 94, 214, 0.2);
  background-color: #fff;
  width: -webkit-fill-available;
  margin: 0 16px 16px;
  background-image: url(${herocardBG});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center 100%;
  padding: 17px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  & img {
    max-width: 36px;
    margin-right: 16px;
  }
  & p {
    font-size: ${props => props.theme.font.s};
    font-weight: 700;
    color: ${props => props.theme.color.secondary};
    line-height: 28px;
  }
  ${media.pc} {
    margin: 0;
    flex-direction: column-reverse;
    background-position: center 85%;
    & p {
      font-size: ${props => props.theme.font.m};
      line-height: normal;
    }
    & img {
      margin-right: 0;
      margin-top: 16px;
      width: 72px;
      height: 72px;
      max-width: 100%;
    }
  }
`

const HeroCard = ({ children, image }) => {
  return (
    <StyledCard>
      <img src={image} alt={children} />
      <p>{children}</p>
    </StyledCard>
  )
}

export default HeroCard
