import React, { useState } from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { media } from "styles/media"
import Button, { StyledButton } from "components/styled/styledButton"
import chirurgia from "images/tooth/chirurgia.svg"
import endodoncja from "images/tooth/endodoncja.svg"
import higienizacja from "images/tooth/higienizacja.svg"
import periodontologia from "images/tooth/periodontologia.svg"
import zachowawcza from "images/tooth/zachowawcza.svg"
import protetyka from "images/tooth/protetyka.svg"
import { specs } from "data/specs"

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(auto, 470px);
  grid-template-rows: auto;
  display: none;
  margin-top: -30px;
  ${media.pc} {
    display: grid;
  }
`

const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  padding: 50px 40px 40px;
  border-radius: 8px;
  box-shadow: 0 8px 16px 0 rgba(37, 94, 214, 0.3);
  background-image: linear-gradient(to left, #167ee6, #255ed6);
  max-width: 470px;
`

const StyledListButton = styled.button`
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  margin-bottom: 20px;
  outline: none !important;
  transition: background-color 0.2s ease, box-shadow 0.2s ease;
  border-radius: 8px;
  & img {
    max-width: 48px;
    margin-right: 30px;
  }
  & p {
    font-size: 20px;
    line-height: 32px;
    color: #fff;
    font-weight: 700;
  }
  &.active {
    background-color: #255ed6;
    box-shadow: 0 4px 8px 0 rgba(16, 28, 53, 0.2);
  }
`

const Content = styled.div`
  align-self: center;
  max-width: 700px;
  border-radius: 8px 0 0 8px;
  background-image: linear-gradient(to right, #101c35 86%, #06132d 100%);
  padding: 40px;
  color: #fff;
  & h3 {
    font-size: 24px;
    margin-bottom: 32px;
    font-weight: 700;
  }
  & .content {
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    letter-spacing: 0.5px;
    font-size: 20px;
    line-height: 32px;
    width: 90%;
    color: #fff;
  }
  ${StyledButton} {
    max-width: 270px;
    margin-bottom: -65px;
    margin-top: 40px;
    font-size: 20px;
    &:hover {
      text-decoration: none;
    }
    & p {
      margin-right: 12px;
      font-size: 20px;
    }
  }
`

const PcSpecs = () => {
  const [currentSpec, setSpec] = useState(specs[0])
  const handleSpec = spec => {
    const currSpec = specs.filter(({ title }) => title === spec)
    setSpec(...currSpec)
  }
  return (
    <Container>
      <Content>
        <h3>{currentSpec.title}</h3>
        <p className="content">{currentSpec.desc}</p>
        <Button cennik tag={Link} to="/cennik/">
          Cennik
        </Button>
      </Content>
      <Buttons>
        <StyledListButton
          onClick={() => handleSpec("Protetyka")}
          className={specs[0].title === currentSpec.title ? "active" : null}
        >
          <img src={protetyka} alt="protetyka" />
          <p>Protetyka</p>
        </StyledListButton>
        <StyledListButton
          onClick={() => handleSpec("Stomatologia Zachowawcza")}
          className={specs[1].title === currentSpec.title ? "active" : null}
        >
          <img src={zachowawcza} alt="zachowawcza" />
          <p>Stomatologia Zachowawcza</p>
        </StyledListButton>
        <StyledListButton
          onClick={() => handleSpec("Chirurgia")}
          className={specs[2].title === currentSpec.title ? "active" : null}
        >
          <img src={chirurgia} alt="chirurgia" />
          <p>Chirurgia</p>
        </StyledListButton>
        <StyledListButton
          onClick={() => handleSpec("Periodontologia")}
          className={specs[3].title === currentSpec.title ? "active" : null}
        >
          <img src={periodontologia} alt="periodontologia" />
          <p>Periodontologia</p>
        </StyledListButton>
        <StyledListButton
          onClick={() => handleSpec("Higienizacja")}
          className={specs[4].title === currentSpec.title ? "active" : null}
        >
          <img src={higienizacja} alt="higienizacja" />
          <p>Higienizacja</p>
        </StyledListButton>
        <StyledListButton
          onClick={() => handleSpec("Endodoncja")}
          className={specs[5].title === currentSpec.title ? "active" : null}
        >
          <img src={endodoncja} alt="endodoncja" />
          <p>Endodoncja</p>
        </StyledListButton>
      </Buttons>
    </Container>
  )
}

export default PcSpecs
