import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "styled-components"
import { media } from "styles/media"
import Background from "gatsby-background-image"
import Button, { StyledButton } from "components/styled/styledButton"
import HeroCard from "components/index/HeroCard"
import bol from "images/ui/bez-bolu.svg"
import skutecznie from "images/ui/skutecznie.svg"
import nowoczesnie from "images/ui/nowoczesnie.svg"
import herocardBG from "images/ui/herocard-bg.svg"

const StyledHeader = styled.header`
  margin-top: 68px;
  ${StyledButton} {
    font-size: 20px;
    padding: 14px 24px;
    margin: 32px 16px 0;
    width: -webkit-fill-available;
  }
  ${media.pc} {
    margin-top: 93px;
    ${StyledButton} {
      width: 570px;
      font-size: ${props => props.theme.font.l};
      height: 90px;
      margin: 0 auto;
    }
  }
`

const BackgroundImageBox = styled(Background)`
  width: 100%;
  height: 240px;
  display: flex;
  align-items: center;
  margin-bottom: -20px;
  background-position: center right;
  background-size: contain;
  ${media.pc} {
    margin: 0 auto -20px;
    height: 490px;
    max-width: 1200px;
  }
  ${media.pcBig} {
    height: 560px;
    margin-bottom: -170px;
  }
`

const Title = styled.h1`
  padding: 0 16px;
  width: 50%;
  font-size: ${props => props.theme.font.xl};
  line-height: 48px;
  color: ${props => props.theme.color.dark2};
  font-weight: 700;
  ${media.tablet} {
    max-width: 576px;
    margin: 0 auto;
    width: 90%;
  }
  ${media.pc} {
    max-width: 992px;
    font-size: ${props => props.theme.font.xxl};
    line-height: 76px;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  ${media.pcBig} {
    max-width: 1200px;
    margin: 0 auto 160px;
  }
`

const Box = styled.div`
  ${media.tablet} {
    max-width: 576px;
    margin: 0 auto;
  }
  ${media.pc} {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 30px;
    max-width: 992px;
  }
  ${media.pcBig} {
    max-width: 1200px;
  }
`

const ButtonWrapper = styled.div`
  ${media.pc} {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: 2 / 1 / 3 / 4;
    border-radius: 8px;
    box-shadow: 0 8px 16px 0 rgba(37, 94, 214, 0.2);
    background-image: url(${herocardBG});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center 100%;
  }
`

const HeroView = () => {
  const { hero, heroPC } = useStaticQuery(graphql`
    query {
      hero: file(relativePath: { eq: "gabinet/background.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      heroPC: file(relativePath: { eq: "gabinet/backgroundPC.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const sources = [
    hero.childImageSharp.fluid,
    {
      ...heroPC.childImageSharp.fluid,
      media: `(min-width: 1024px)`,
    },
  ]

  return (
    <StyledHeader>
      <BackgroundImageBox fluid={sources}>
        <Title>Tworzymy wspaniałe uśmiechy</Title>
      </BackgroundImageBox>
      <Box>
        <HeroCard image={bol}>Bez bólu</HeroCard>
        <HeroCard image={skutecznie}>Skutecznie</HeroCard>
        <HeroCard image={nowoczesnie}>Nowocześnie</HeroCard>
        <ButtonWrapper>
          <Button tag="a" call link="tel:748311910">
            Umów wizytę
          </Button>
        </ButtonWrapper>
      </Box>
    </StyledHeader>
  )
}

export default HeroView
