import React from "react"
import styled from "styled-components"
import { StyledTitle } from "components/styled/styledTitle"
import { StyledSubtitle } from "components/styled/styledSubtitle"
import MobileCollapseWidget from "components/index/MobileCollapseWidget"
import PcSpecs from "components/index/PcSpecs"
import { media } from "styles/media"

const StyledSection = styled.section`
  margin: 64px auto;
  ${media.pc} {
    margin: 160px auto;
  }
`

const Specializations = () => {
  return (
    <StyledSection>
      <div className="container">
        <StyledSubtitle>Usługi</StyledSubtitle>
        <StyledTitle>Nasze specjalizacje</StyledTitle>
        <MobileCollapseWidget />
        <PcSpecs />
      </div>
    </StyledSection>
  )
}

export default Specializations
