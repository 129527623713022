import React from "react"

export const specs = [
  {
    title: "Protetyka",
    desc: (
      <>
        Leczenie protetyczne polega na trwałej odbudowie lub odtworzeniu brakujących zębów.
        <br />
        <br />
        Zdobycze dzisiejszej stomatologii dają nam szeroki wachlarz możliwości zastosowania odpowiedniej metody dla
        danego przypadku.
      </>
    ),
  },
  {
    title: "Stomatologia Zachowawcza",
    desc: (
      <>
        Ten dział stomatologii zajmuje się zapobieganiem próchnicy zębów oraz jej leczeniem.
        <br />
        <br />W zakres stomatologii zachowawczej wchodzą takie zabiegi jak leczenie kanałowe, leczenie próchnicy czy
        kosmetyczna odbudowa zębów.
      </>
    ),
  },
  {
    title: "Chirurgia",
    desc: (
      <>
        Są to zaawansowane zabiegi chirurgiczne o różnym stopniu skomplikowania, głównie usuwanie zębów.
        <br />
        <br />
        Wszelkie zabiegi chirurgiczne wykonujemy z zastosowaniem znieczulenia miejscowego, dzięki czemu są one w 100%
        bezbolesne.
      </>
    ),
  },
  {
    title: "Periodontologia",
    desc: (
      <>
        Zajmujemy się leczeniem chorób przyzębia oraz schorzeniami błon śluzowych jamy ustnej.
        <br />
        <br />
        Leczymy stany zapalne przyzębia, błony śluzowej i dziąseł. Dodatkowo usuwamy kamień nazębny oraz wszelkie inne
        nieprzyjemne osady.
      </>
    ),
  },
  {
    title: "Higienizacja",
    desc: (
      <>
        Jest to profilaktyczne oczyszczanie zębów. Usuwamy wtedy osady oraz kamień nazębny.
        <br />
        <br />
        Wpływa to korzystnie na ogólny stan Twojego uzębienia. Zabiegi te mogą również przynieśćefekty wybielający dla
        Twoich zębów.
      </>
    ),
  },
  {
    title: "Endodoncja",
    desc: (
      <>
        Inaczej leczenie kanałowe. Jest stosowane w przypadku stanów martwiczych i zapalenia.
        <br />
        <br />
        Skorzystanie z tego zabiegu pozwala często uniknąć ekstrakcji zęba. Dzięki nowoczesnej technologii leczenie to
        jest niemalże bezbolesne.
      </>
    ),
  },
]
