import React from "react"
import styled from "styled-components"
import Slider from "react-slick"
import { media } from "styles/media"
import { StyledTitle } from "components/styled/styledTitle"
import { StyledSubtitle } from "components/styled/styledSubtitle"
import stars from "images/ui/stars.svg"
import marcel from "images/persons/marcel.png"
import adam from "images/persons/adam.png"
import wojtek from "images/persons/wojtek.jpg"
import bg from "images/opinionsBG.svg"

const StyledSection = styled.section`
  margin: 64px 0;
  & .slick-slider {
    ${media.tablet} {
      max-width: 576px;
      margin: 0 auto;
    }
    ${media.pc} {
      display: none;
    }
    .slick-track {
      padding: 16px 0;
      display: flex;
      align-items: center;
    }
    .slick-slide {
      width: calc(100vw - 32px);
      transform: translateX(16px);
      margin: 0 4px;
      ${media.tablet} {
        transform: translateX(0);
      }
    }
    .slick-dots {
      width: calc(100vw - 32px);
      transform: translateX(16px);
      bottom: 4px;
      display: grid !important;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 18px;
      ${media.tablet} {
        max-width: 576px;
        margin: 0 auto;
      }
      li {
        width: 100%;
        margin: 0;
        button {
          width: 100%;
          position: relative;
          &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            top: auto;
            width: 100%;
            height: 2px;
            background-color: #daebfa;
            content: "";
            opacity: 1 !important;
          }
        }
      }
      li.slick-active {
        button {
          &::before {
            background-color: #255ed6;
          }
        }
      }
    }
  }
`

const Opinion = styled.div`
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(37, 94, 214, 0.2);
  background-color: #fff;
  padding: 18px 16px;
  & .opinion {
    margin-top: 10px;
    letter-spacing: 0.2px;
    color: ${props => props.theme.color.dark2};
  }
  ${media.pc} {
    padding: 30px;

    & .opinion {
      font-size: 20px;
      line-height: 32px;
    }
  }
`

const Person = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & p.name {
    font-size: ${props => props.theme.font.s};
    line-height: 28px;
    color: ${props => props.theme.color.primary};
    font-weight: 700;
  }
  & img.person {
    max-width: 36px;
    margin-right: 12px;
  }
  & img.stars {
    max-width: 96px;
  }
  ${media.pc} {
    & p.name {
      font-size: ${props => props.theme.font.m};
      line-height: normal;
    }
    & img.person {
      max-width: 50px;
      margin-right: 20px;
    }
    & img.stars {
      max-width: 144px;
    }
  }
`

const Box = styled.div`
  display: flex;
  align-items: center;
`

const PcOpinions = styled.div`
  display: none;
  ${media.pc} {
    display: grid;
    max-width: 1200px;
    margin: 50px auto 140px;
    grid-template-columns: repeat(2, minmax(auto, 570px));
    grid-template-rows: repeat(2, minmax(auto, 185px));
    grid-gap: 30px;
    position: relative;
    &::before {
      content: "";
      width: 100vw;
      max-width: 1440px;
      position: absolute;
      height: 340px;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: #daebfa;
      background-image: url(${bg});
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center 80%;
      z-index: -1;
      border-radius: 8px;
    }
    // prettier-ignore
    ${Opinion}:nth-child(2) {
      transform: translateY(50%);
    }
  }
`

const Opinions = () => {
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
  }
  return (
    <StyledSection>
      <div className="container">
        <StyledSubtitle>Opinie</StyledSubtitle>
        <StyledTitle>Co mówią o nas ludzie</StyledTitle>
      </div>
      <Slider {...settings}>
        <Opinion>
          <Person>
            <Box>
              <img src={wojtek} alt="Wojciech Smoła" className="person" />
              <p className="name">Wojciech Smoła</p>
            </Box>
            <img src={stars} alt="Ocena" className="stars" />
          </Person>
          <p className="opinion">
            Profesjonalizm pana Bartosza jest na poziomie godnym polecenia. Bardzo elastyczne terminy wizyt!
          </p>
        </Opinion>
        <Opinion>
          <Person>
            <Box>
              <img src={marcel} alt="Marcel Bednarz" className="person" />
              <p className="name">Marcel Bednarz</p>
            </Box>
            <img src={stars} alt="Ocena" className="stars" />
          </Person>
          <p className="opinion">
            Przyjechałem z Wrocławia z polecenia i w sumie nie żałuję. Moje zęby są jak nowe :){" "}
          </p>
        </Opinion>
        <Opinion>
          <Person>
            <Box>
              <img src={adam} alt="Adam Mikołajek" className="person" />
              <p className="name">Adam Mikołajek</p>
            </Box>
            <img src={stars} alt="Ocena" className="stars" />
          </Person>
          <p className="opinion">
            Trzeci zabieg z rzędu przebiegł pomyślnie. Pan Bartosz posiada ogromną wiedzę i udziela przydatnych porad.
          </p>
        </Opinion>
      </Slider>
      <PcOpinions>
        <Opinion>
          <Person>
            <Box>
              <img src={wojtek} alt="Wojciech Smoła" className="person" />
              <p className="name">Wojciech Smoła</p>
            </Box>
            <img src={stars} alt="Ocena" className="stars" />
          </Person>
          <p className="opinion">
            Profesjonalizm pana Bartosza jest na poziomie godnym polecenia. Bardzo elastyczne terminy wizyt!
          </p>
        </Opinion>
        <Opinion>
          <Person>
            <Box>
              <img src={marcel} alt="Marcel Bednarz" className="person" />
              <p className="name">Marcel Bednarz</p>
            </Box>
            <img src={stars} alt="Ocena" className="stars" />
          </Person>
          <p className="opinion">
            Przyjechałem z Wrocławia z polecenia i w sumie nie żałuję. Moje zęby są jak nowe :){" "}
          </p>
        </Opinion>
        <Opinion>
          <Person>
            <Box>
              <img src={adam} alt="Adam Mikołajek" className="person" />
              <p className="name">Adam Mikołajek</p>
            </Box>
            <img src={stars} alt="Ocena" className="stars" />
          </Person>
          <p className="opinion">
            Trzeci zabieg z rzędu przebiegł pomyślnie. Pan Bartosz posiada ogromną wiedzę i udziela przydatnych porad.
          </p>
        </Opinion>
      </PcOpinions>
    </StyledSection>
  )
}

export default Opinions
