import React, { useState } from "react"
import styled from "styled-components"
import { media } from "styles/media"
import { Collapse } from "reactstrap"
import Button, { StyledButton } from "components/styled/styledButton"
import chirurgia from "images/tooth/chirurgia.svg"
import endodoncja from "images/tooth/endodoncja.svg"
import higienizacja from "images/tooth/higienizacja.svg"
import periodontologia from "images/tooth/periodontologia.svg"
import zachowawcza from "images/tooth/zachowawcza.svg"
import protetyka from "images/tooth/protetyka.svg"
import arrow from "images/ui/white_arrow.svg"

const Wrapper = styled.div`
  ${StyledButton} {
    padding: 14px 24px;
    width: -webkit-fill-available;
    color: #fff;
    font-size: 20px;
    & p:first-of-type {
      margin-right: 8px;
    }
  }
  ${media.pc} {
    display: none;
  }
`

const StyledWrapper = styled.div`
  background-image: ${props => props.theme.color.blue};
  border-radius: 8px;
  box-shadow: 0 4px 8px 0 rgba(37, 94, 214, 0.3);
  padding: 24px 16px 6px;
  margin: 16px 0;
`

const CustomButton = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
  width: 100%;
  outline: none !important;
  & img {
    margin-right: 8px;
    max-width: 36px;
    &.arrow {
      margin-right: 0;
      margin-left: 4px;
      max-width: 24px;
      transition: 0.2s ease;
      &.active {
        transform: rotate(180deg);
      }
    }
  }
  & p.desc {
    font-size: ${props => props.theme.font.s};
    color: #fff;
    font-weight: 700;
    line-height: 28px;
  }
`

const Container = styled.div`
  margin-bottom: 20px;
  & p {
    text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
    letter-spacing: 0.2px;
    font-size: ${props => props.theme.font.xxs};
    line-height: 18px;
    font-weight: 500;
    color: #fff;
    margin-left: 8px;
  }
`

const MobileUncontrolledCollapseWidget = () => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <Wrapper>
      <StyledWrapper>
        <CustomButton id="protetyka" onClick={() => setIsOpen("protetyka")}>
          <img src={protetyka} alt="protetyka" />
          <p className="desc">Protetyka</p>
          <img src={arrow} alt="arrow" className={[isOpen === "protetyka" ? "active" : null, "arrow"].join(" ")} />
        </CustomButton>
        <Collapse isOpen={isOpen === "protetyka"}>
          <Container>
            <p>
              Leczenie protetyczne polega na trwałej odbudowie lub odtworzeniu brakujących zębów.
              <br />
              <br />
              Zdobycze dzisiejszej stomatologii dają nam szeroki wachlarz możliwości zastosowania odpowiedniej metody
              dla danego przypadku.
            </p>
          </Container>
        </Collapse>
        <CustomButton onClick={() => setIsOpen("zachowawcza")}>
          <img src={zachowawcza} alt="zachowawcza" />
          <p className="desc">Stomatologia Zachowawcza</p>
          <img src={arrow} alt="arrow" className={[isOpen === "zachowawcza" ? "active" : null, "arrow"].join(" ")} />
        </CustomButton>
        <Collapse isOpen={isOpen === "zachowawcza"}>
          <Container>
            <p>
              Ten dział stomatologii zajmuje się zapobieganiem próchnicy zębów oraz jej leczeniem.
              <br />
              <br />W zakres stomatologii zachowawczej wchodzą takie zabiegi jak leczenie kanałowe, leczenie próchnicy
              czy kosmetyczna odbudowa zębów.
            </p>
          </Container>
        </Collapse>
        <CustomButton onClick={() => setIsOpen("chirurgia")}>
          <img src={chirurgia} alt="chirurgia" />
          <p className="desc">Chirurgia</p>
          <img src={arrow} alt="arrow" className={[isOpen === "chirurgia" ? "active" : null, "arrow"].join(" ")} />
        </CustomButton>
        <Collapse isOpen={isOpen === "chirurgia"}>
          <Container>
            <p>
              Są to zaawansowane zabiegi chirurgiczne o różnym stopniu skomplikowania, głównie usuwanie zębów.
              <br />
              <br />
              Wszelkie zabiegi chirurgiczne wykonujemy z zastosowaniem znieczulenia miejscowego, dzięki czemu są one w
              100% bezbolesne.
            </p>
          </Container>
        </Collapse>
        <CustomButton onClick={() => setIsOpen("periodontologia")}>
          <img src={periodontologia} alt="periodontologia" />
          <p className="desc">Periodontologia</p>
          <img
            src={arrow}
            alt="arrow"
            className={[isOpen === "periodontologia" ? "active" : null, "arrow"].join(" ")}
          />
        </CustomButton>
        <Collapse isOpen={isOpen === "periodontologia"}>
          <Container>
            <p>
              Zajmujemy się leczeniem chorób przyzębia oraz schorzeniami błon śluzowych jamy ustnej.
              <br />
              <br />
              Leczymy stany zapalne przyzębia, błony śluzowej i dziąseł. Dodatkowo usuwamy kamień nazębny oraz wszelkie
              inne nieprzyjemne osady.
            </p>
          </Container>
        </Collapse>
        <CustomButton onClick={() => setIsOpen("higienizacja")}>
          <img src={higienizacja} alt="higienizacja" />
          <p className="desc">Higienizacja</p>
          <img src={arrow} alt="arrow" className={[isOpen === "higienizacja" ? "active" : null, "arrow"].join(" ")} />
        </CustomButton>
        <Collapse isOpen={isOpen === "higienizacja"}>
          <Container>
            <p>
              Jest to profilaktyczne oczyszczanie zębów. Usuwamy wtedy osady oraz kamień nazębny.
              <br />
              <br />
              Wpływa to korzystnie na ogólny stan Twojego uzębienia. Zabiegi te mogą również przynieśćefekty wybielający
              dla Twoich zębów.
            </p>
          </Container>
        </Collapse>
        <CustomButton onClick={() => setIsOpen("endodoncja")}>
          <img src={endodoncja} alt="endodoncja" />
          <p className="desc">Endodoncja</p>
          <img src={arrow} alt="arrow" className={[isOpen === "endodoncja" ? "active" : null, "arrow"].join(" ")} />
        </CustomButton>
        <Collapse isOpen={isOpen === "endodoncja"}>
          <Container>
            <p>
              Inaczej leczenie kanałowe. Jest stosowane w przypadku stanów martwiczych i zapalenia.
              <br />
              <br />
              Skorzystanie z tego zabiegu pozwala często uniknąć ekstrakcji zęba. Dzięki nowoczesnej technologii
              leczenie to jest niemalże bezbolesne.
            </p>
          </Container>
        </Collapse>
      </StyledWrapper>
      <Button tag="a" link="/cennik/" cennik>
        Cennik
      </Button>
    </Wrapper>
  )
}

export default MobileUncontrolledCollapseWidget
