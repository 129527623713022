import React from "react"
import Layout from "components/layout/layout"
import SEO from "components/seo"
import HeroView from "components/index/HeroView"
// import Team from "components/index/Team"
import Specializations from "components/index/Specializations"
import Opinions from "components/index/Opinions"
import "bootstrap/dist/css/bootstrap.min.css"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HeroView />
    {/* <Team /> */}
    <Specializations />
    <Opinions />
  </Layout>
)

export default IndexPage
